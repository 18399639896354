import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import { CSVLink, CSVDownload } from 'react-csv';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  splitByChar,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import moment from 'moment';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const MachineUtilizationDailyReportPage = () => {
  const [itemData, setItemData] = useState();
  const [showDownload, setShowDownload] = useState(false);
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  const state = useContext(GlobalStateData);
  useEffect(() => {
    authCheck(state.authData);
    getMachines();
  }, []);

  useEffect(() => {
    if (tableData.length) {
      setShowDownload(true);
    } else {
      setShowDownload(false);
    }
  }, [tableData]);

  const [machinData, setMachineData] = useState([]);
  const [defaultMachine, setDefaultMachine] = useState([{ label: 'All', value: '' }]);
  const [machineOptions, setMachineOptions] = useState([]);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const postApi = () => {
    const postData = {
      data: {
        machinData,
        from,
        to,
      },
    };
    let validate = requireCheck(postData);
    if (from && to) {
      var deliveryDateValid = dateGreaterOrEqual(to, from, 'to', 'Invalid To Date');
    } else {
      var deliveryDateValid = true;
    }

    if (!validate || !deliveryDateValid) {
      return false;
    }
    let machinDataVal = machinData.toString();
    showLoader();
    axios
      .get(baseUri() + 'reports/machine-util-daily?machineId=' + machinDataVal + '&from=' + from + '&to=' + to, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let prevTotalCount = '';
        let prevIdleCount = '';
        let prevMachineCode = '';
        response.data.data.forEach(
          (t: {
            id: any;
            mc: any;
            machine_name: any;
            oc: any;
            group_name: any;
            operation_name: any;
            start_time: any;
            end_time: any;
            LMtrs: any;
            weight: any;
            duration: any;
          }) => {},
        );
        setItemData(response.data.data);
        if (tableFilter) {
          filterChange(tableFilter, response.data.data);
        } else {
          setTableData(response.data.data);
        }
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const handleChange = (event) => {
    resetRequired(event);
    switch (event.target.name) {
      case 'from':
        setFrom(event.target.value);
        if (event.target.value) {
          document.getElementsByClassName('to')[0].classList.add('require');
        } else {
          document.getElementsByClassName('to')[0].classList.remove('require');
        }
        break;
      case 'to':
        setTo(event.target.value);
        break;
    }
  };

  const machineChange = (event) => {
    let selected2 = [];
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    event.forEach(function (item, index) {
      selected2.push(item.value);
    });
    setMachineData(selected2);
    setDefaultMachine(event);
    pullDownReset('machine');
  };

  const clearFormData = () => {
    setFrom('');
    setTo('');
    setMachineData([]);
    setDefaultMachine([{ label: 'All', value: '' }]);
    resetRequired();
    document.getElementsByClassName('to')[0].classList.remove('require');
  };

  const getMachines = () => {
    showLoader();
    axios
      .get(
        baseUri() +
          `machines?populate[0]=groupOps&populate[1]=groupOps.group&populate[2]=groupOps.operations&sort[0]=id:desc`,
        {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        let options = [];
        let groupInfo = [];
        response.data.data.forEach(function (item, index) {
          options.push({ label: item.attributes.machineCode + ' - ' + item.attributes.description, value: item.id });
          groupInfo.push({ id: item.id, groupOps: item.attributes.groupOps });
        });
        setMachineOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const columns = [
    {
      name: 'ID',
      selector: (row) => `${row.id}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Machine Code',
      selector: (row) => `${row.mc}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Machine Name',
      selector: (row) => `${row.machine_name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Operation Code',
      selector: (row) => `${row.oc}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Group Name',
      selector: (row) => `${row.group_name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Operation Name',
      selector: (row) => `${row.operation_name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Start Time',
      selector: (row) => `${row.Start_Time}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'End Time',
      selector: (row) => `${row.End_Time}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'L Mts',
      selector: (row) => `${row.LMtrs}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Weight',
      selector: (row) => `${row.weight}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Duration',
      selector: (row) => `${row.Duration}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Duration_100',
      selector: (row) => `${row.Duration_100}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Idle_100',
      selector: (row) => `${row.Idle_100}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Mode',
      selector: (row) => `${row.Mode}`,
      sortable: true,
      maxWidth: '45%',
    },
  ];
  const filterColumns = [
    'id',
    'mc',
    'machine_name',
    'oc',
    'group_name',
    'operation_name',
    'Start_Time',
    'End_Time',
    'LMtrs',
    'weight',
    'Duration',
    'Duration_100',
    'Idle_100',
    'Mode'
  ];

  const filterChange = (searchInput, newData = false) => {
    setTableFilter(searchInput);
    let available = [];
    if (newData) {
      available = newData;
    } else {
      available = itemData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns, true);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };

  return (
    <>
      <SEO title="Machine Utilization Daily" />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header> Machine Utilization - Daily</header>
            <CardBody className="cardbody">
              <Row>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Machine</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => machineChange(e)}
                    options={machineOptions}
                    id="machine"
                    name="machine"
                    placeholder="Select Machine"
                    value={defaultMachine}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>From Date</label>
                  <Input fullWidth size="Small">
                    <input
                      type="datetime-local"
                      name="from"
                      placeholder="From Date"
                      onChange={(e) => handleChange(e)}
                      value={from}
                      className="from"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>To Date</label>
                  <Input fullWidth size="Small">
                    <input
                      type="datetime-local"
                      name="to"
                      placeholder="To Date"
                      onChange={(e) => handleChange(e)}
                      value={to}
                      className="to"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <Button status="Success" type="button" className="btnrad" shape="" halfWidth onClick={postApi}>
                    Get Report
                  </Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Machine Utilization - Daily</header>
            <CardBody>
              {showDownload && (
                <p>
                  <CSVLink
                    style={{
                      marginLeft: '5px',
                    }}
                    data={tableData}
                  >
                    Download Excel
                  </CSVLink>
                </p>
              )}
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => filterChange(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MachineUtilizationDailyReportPage;
